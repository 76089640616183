import React, {useContext} from 'react'
import {Checkbox, Typography} from '@material-ui/core'
import CircleChecked from '@material-ui/icons/CheckCircleOutline'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import useStyles from '../FooterStyles'
import {Quote} from '../../../../services/quote/QuoteProvider'
import I18nContext from '../../../../services/I18n/I18nContext'
import {currency} from '../../../containers/Services/components/ServiceItem'

interface IFixedCalculationProps {
    showAdditionalFooter: boolean,
    quote: Quote
}

const FixedCalculation = (props: IFixedCalculationProps) => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)
    const {quote} = props

    const activationPrice = 99
    const dgfe = 79
    const basicService = quote.items.find(i => i.id === 'basicService1')
    const deviceItem = quote.items.find(i => i.id === 'device_one')
    const option1 = quote.items.find(i => i.id === 'option1')
    const deviceFixedPrice = deviceItem && ((deviceItem.fixedPrice || 0) * deviceItem.quantity)

    return (
        <div className={classes.fixedCalculationRoot}>
            {
                quote.totalFixedPrevious > 0 && (
                    <Typography className={classes.previousPriceLabel}>
                        {quote.totalFixedPrevious} {currency}
                    </Typography>
                )
            }
            <div className={classes.row}>
                <Typography className={classes.label}>
                    {translate('now')}
                </Typography>
                {
                    quote.totalFixed > 0 && (
                        <Typography className={classes.mainPrice}>
                            {quote.totalFixed} {currency}
                        </Typography>
                    )
                }
                {
                    quote.totalFixed > 0 && (
                        <Typography className={classes.secondaryLabel}>
                            {translate('pdv')}
                        </Typography>
                    )
                }
            </div>
            <div className={classes.row}>
                <Typography className={classes.message}>
                    {translate('activationMessage')}
                </Typography>
            </div>
            {props.showAdditionalFooter && (
                <>
                    <div className={classes.row}>
                        <Checkbox
                            className={classes.actionCheckbox}
                            color='primary'
                            checked={true}
                            icon={<CircleUnchecked/>}
                            checkedIcon={<CircleChecked/>}
                        />
                        <div className={classes.additionalActionWrapper}>
                            <Typography className={classes.additionalActionMessage}>
                                {translate('rtActivation')}
                            </Typography>
                            <Typography className={classes.additionalActionPrice}>
                                {(basicService ? basicService.quantity : 1) * activationPrice} {currency}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <Checkbox
                            className={classes.actionCheckbox}
                            color='primary'
                            checked={true}
                            icon={<CircleUnchecked/>}
                            checkedIcon={<CircleChecked/>}
                        />
                        <div className={classes.additionalActionWrapper}>
                            <Typography className={classes.additionalActionMessage}>
                                {translate('dgfe')}
                            </Typography>
                            <Typography className={classes.additionalActionPrice}>
                                {(basicService ? basicService.quantity : 1) * dgfe} {currency}
                            </Typography>
                        </div>
                    </div>
                    {deviceFixedPrice && (
                        <div className={classes.row}>
                            <Checkbox
                                className={classes.actionCheckbox}
                                color='primary'
                                checked={true}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CircleChecked/>}
                            />
                            <div className={classes.additionalActionWrapper}>
                                <Typography className={classes.additionalActionMessage}>
                                    {translate('lettoreCartActivation')}
                                </Typography>
                                <Typography className={classes.additionalActionPrice}>
                                    {deviceFixedPrice} {currency}
                                </Typography>
                            </div>
                        </div>
                    )}
                    {option1 && (
                        <div className={classes.row}>
                            <Checkbox
                                className={classes.actionCheckbox}
                                color='primary'
                                checked={true}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CircleChecked/>}
                            />
                            <div className={classes.additionalActionWrapper}>
                                <Typography className={classes.additionalActionMessage}>
                                    {translate('priceListEntry')}
                                </Typography>
                                <Typography className={classes.additionalActionPrice}>
                                    {option1.price} {currency}
                                </Typography>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )

}

export default FixedCalculation
