import React, {useState, useContext, useEffect} from 'react'
import {useHistory} from 'react-router'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import {Container} from '@material-ui/core'
import {useLocation} from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import useStyles from './FooterStyles'
import FixedCalculation from './components/FixedCalculation'
import MonthlyCalculation from './components/MonthlyCalculation'
import CustomButton from '../CustomButton/CustomButton'
import I18nContext from '../../../services/I18n/I18nContext'
import {ActionData, QuoteAction, QuoteContext} from '../../../services/quote/QuoteProvider'
import {mdBreakpoint} from '../../../utils/constants/constants'
import {saveQuote} from '../../../services/api/index'

const Footer = () => {

    const classes = useStyles()
    const history = useHistory()
    const {translate} = useContext(I18nContext)
    const location = useLocation()
    const {dispatch, quote} = useContext(QuoteContext)
    const [showAdditionalFooter, setShowAdditionalFooter] = useState(false)
    const [showApplyQuoteButton, setShowApplyQuoteButton] = useState(false)
    const [footerExpanded, setFooterExpanded] = useState(false)
    const [savingQuote, setSavingQuote] = useState(false)

    const goToForm = () => {
        if (window.innerWidth > mdBreakpoint) setShowAdditionalFooter(false)
        window.scrollTo({top: 0});
        history.push('/form')
    }

    const handleToggleFooter = () => {
        if (window.innerWidth <= mdBreakpoint) setFooterExpanded(!footerExpanded)
        setShowAdditionalFooter(!showAdditionalFooter)
    }

    const prepareDeliveryAddress = () => {
        const form = quote.form
        if (!form) return
        return {
            da_firstname: form.deliveryFirstName,
            da_lastname: form.deliveryLastName,
            da_address: form.deliveryAddress,
            da_city: form.deliveryPlace,
            da_zip: form.deliveryZip,
            da_region: form.deliveryProvince,
            da_company: form.deliveryCompany,
            da_note: form.deliveryNote
        }
    }

    const prepareItems = (quote: any) => {
        let finalItems: any = {}

        const basicService = quote.items.find((d: any) => d.id === 'basicService1')

        finalItems = {
            ...finalItems,
            [basicService.auto_code]: basicService.quantity
        }

        quote.items.forEach((item: any) => {
            if (!item.auto_code) return;

            finalItems = {
                ...finalItems,
                [item.auto_code]: item.quantity
            }
        })

        return finalItems
    }

    const prepareQuoteForSave = () => {
        const form = quote.form
        if (!form) return

        const externalUse = quote.useType === 'external' ? 'yes' : 'no'

        let apiData = {
            vat_number: form.partitaIva,
            first_name: form.firstName,
            last_name: form.lastName,
            company_name: form.company,
            phone: form.phone,
            email: form.email,
            address: form.address,
            city: form.region,
            region: form.province,
            zip: Number(form.zip),
            delivery_address: form.deliveryAddressChecked ? 'yes' : 'no',
            einvoice_code: form.code,
            external_use: externalUse,
            items: prepareItems(quote),

        } as any

        if (form.deliveryAddressChecked) apiData = {...apiData, ...prepareDeliveryAddress()}

        return apiData
    }

    const applyQuote = () => {
        setSavingQuote(true)
        saveQuote(prepareQuoteForSave()).then(res => {
            setSavingQuote(false)

            window.dataLayer.push(res)

            dispatch({
                data: {quoteNotification: 'success'} as ActionData,
                type: QuoteAction.setQuoteNotification,
            })
        }).catch(err => {
            setSavingQuote(false)

            dispatch({
                data: {quoteNotification: 'fail'} as ActionData,
                type: QuoteAction.setQuoteNotification,
            })
        })
    }

    useEffect(() => {
        setShowApplyQuoteButton(location.pathname === '/form')
    }, [location])

    return (
        <div className={clsx(classes.root, (footerExpanded && 'footer-active'))}>
            <Container>
                <div className={classes.footerContainer}>
                    <div className={classes.actionsWrapper} onClick={handleToggleFooter}>
                        <ExpandMoreIcon
                            className={clsx(classes.expandIcon, (showAdditionalFooter && classes.expandIconActive))}/>
                        <Typography className={classes.paymentTitle}>
                            {translate('payment')}
                        </Typography>
                    </div>
                    {/* <div className={classes.couponCodeWrapper}>
                        <CouponCode dispatch={dispatch} quote={quote}/>
                    </div>*/}
                    <div className={classes.calculationWrapper}>
                        <div className={classes.fixedCalculationWrapper}>
                            <FixedCalculation quote={quote} showAdditionalFooter={showAdditionalFooter}/>
                        </div>
                        <div className={classes.monthlyCalculationWrapper}>
                            <MonthlyCalculation quote={quote} showAdditionalFooter={showAdditionalFooter}/>
                        </div>
                    </div>
                    <div className={classes.buttonWrapper}>
                        {showApplyQuoteButton ? (
                            <CustomButton text={translate('applyQuote')}
                                          onClick={applyQuote}
                                          disabled={!quote.isFormValid || quote.totalMonthly < 1 || savingQuote}
                                          icon={<ArrowForwardIosIcon className={classes.buttonIcon}/>}
                                          className={classes.button}
                                          textClass={classes.buttonText}
                            />
                        ) : (
                            <CustomButton text={translate('next')}
                                          onClick={goToForm}
                                          icon={<ArrowForwardIosIcon className={classes.buttonIcon}/>}
                                          className={classes.button}
                                          textClass={classes.buttonText}
                            />
                        )}
                    </div>
                </div>
            </Container>
        </div>
    )

}

export default Footer
